import Head from "next/head";
import { GetServerSideProps } from "next";
import { LoginCard } from "components/web-checkout/login-card/login-card";
import { LoginBackground } from "components/web-checkout/login-background/login-background";
import { Main } from "styles/pages/web-checkout.styles";
import { ILoginPageProps } from "types/page.types";
import { LoadingSpinner } from "components/icons/loading-spinner";
import { PageLoader } from "styles/common/pageLoader";
import { useLogin } from "hooks/pages/use-login/use-login";
import { CheckoutSummaryCard } from "components/paket/checkout-summary-card/checkout-summary-card";

const Login: React.FC<ILoginPageProps> = ({ documentReferrer }) => {
  const {
    isUserLoggedIn,
    routerPath,
    setFormPhase,
    formPhase,
    onCloseTenureChangeModal,
    onChangeTenureClick,
    isTenureSelectModalOpen,
    onClickContinueTenurePopup,
    selectedTenureId,
    setSelectedTenureId,
    prevLink,
    packageId,
    tenureId,
    packageSummaryData,
    packageData
  } = useLogin({
    documentReferrer
  });

  const MainContainer = packageId && tenureId ? Main : LoginBackground;

  if (isUserLoggedIn) {
    return (
      <PageLoader>
        <LoadingSpinner />
      </PageLoader>
    );
  }

  return (
    <MainContainer routerPath={routerPath} prevLink={prevLink}>
      <Head>
        <title>CoLearn | Masuk ke website</title>
      </Head>
      <LoginCard
        summaryPackageData={packageSummaryData?.data}
        setFormPhase={setFormPhase}
        formPhase={formPhase}
      />
      {packageId && tenureId && (
        <CheckoutSummaryCard
          packageSummaryData={packageSummaryData}
          onChangeTenureClick={onChangeTenureClick}
          onCloseTenureChangeModal={onCloseTenureChangeModal}
          isTenureSelectModalOpen={isTenureSelectModalOpen}
          packageData={packageData}
          selectedTenureId={selectedTenureId}
          onClickContinueTenurePopup={onClickContinueTenurePopup}
          setSelectedTenureId={setSelectedTenureId}
        />
      )}
    </MainContainer>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  const documentReferrer = req.headers.referer ? req.headers.referer : "";
  return {
    props: {
      documentReferrer
    }
  };
};

export default Login;
