import { useState, useEffect } from "react";
import { IUseCountdownTimer } from "./countdown-timer.types";

export const useContdownTimer: IUseCountdownTimer = ({
  minutes = 0,
  seconds,
  onReset
}) => {
  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });
  const [[mins, secs], setTime] = useState([minutes, seconds]);
  const tick = () => {
    if (mins === 0 && secs === 0) {
      onReset();
      reset();
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };
  const reset = () => setTime([minutes, seconds]);

  return {
    mins,
    secs
  };
};
