import styled from "styled-components";
import { FieldError } from "react-hook-form";
import { FormFieldWithError } from "../field-error/field-error.styles";

export const OTPInputDiv = styled(FormFieldWithError)`
  padding: 8px 0px;
`;

export const OTPInputField = styled.input<{
  error?: FieldError;
  isDirty?: boolean;
}>`
  width: 42px;
  height: 42px;
  border: 2px solid
    ${({ theme, error, isDirty }) => {
      return error?.message
        ? theme.colors.red[100]
        : isDirty
          ? theme.colors.brand["500"]
          : theme.colors.periwinkle[500];
    }};
  border-radius: 6px;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  text-align: center;
  &:focus {
    outline: none;
  }
`;

export const OTPDiv = styled.div`
  display: flex;
  gap: 8px;
`;
