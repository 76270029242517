import { CountdownTimer } from "components/countdown-timer/countdown-timer";
import { MobileInput } from "components/form-component/mobile-input/mobile-input";
import { OTPInput } from "components/form-component/otp-input/otp-input";
import { constants } from "constants/constants";
import React from "react";
import { useValidateOTP } from "../hooks/use-validate-otp";
import {
  OTPInputWrapper,
  ResendButton,
  CountdownWrapper,
  LoginCTAWrapper,
  StyledLoginForm,
  LoginCTA
} from "../login-card.styles";
import { IValidateOTPProps } from "../login-card.types";

const ValidateOTP: React.FC<IValidateOTPProps> = ({
  setFormPhase,
  token,
  setToken,
  phoneNumber,
  summaryPackageData
}) => {
  const {
    handleSubmit,
    onSubmit,
    editNumber,
    enableResendOTP,
    resendOTPAction,
    resendOTPCount,
    isValid,
    control,
    isOTPTimerActive,
    isVerificationLoading
  } = useValidateOTP({
    setFormPhase,
    token,
    setToken,
    phoneNumber,
    summaryPackageData
  });

  return (
    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
      <MobileInput
        name={"phone"}
        label={"Nomor HP"}
        placeholder={"Ketik nomor HP di sini"}
        control={control}
        isDisabled={true}
        editAction={editNumber}
      />

      <OTPInputWrapper>
        <OTPInput
          numInputs={6}
          control={control}
          name="otp"
          label="Kode verifikasi"
        />
        {resendOTPCount < 1 && (
          <ResendButton
            type="button"
            onClick={resendOTPAction}
            disabled={isOTPTimerActive}
            data-testid="resend-otp-button">
            Kirim lagi kodenya
          </ResendButton>
        )}

        {isOTPTimerActive && (
          <CountdownWrapper>
            <CountdownTimer
              onReset={enableResendOTP}
              seconds={constants.OTP_RESEND_TIME}
            />
          </CountdownWrapper>
        )}
      </OTPInputWrapper>
      <LoginCTAWrapper>
        <LoginCTA
          isLoading={isVerificationLoading}
          loadingText={"Memverifikasi"}
          disabled={!isValid}
          type="submit"
          variant="primary"
          data-testid="verify-otp-button">
          Verifikasi
        </LoginCTA>
      </LoginCTAWrapper>
    </StyledLoginForm>
  );
};

export { ValidateOTP };
