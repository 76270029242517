import React from "react";
import { BackBtnStyles } from "./icons.styles";
import type { IIcon } from "./icons.types";

const BackBtn: React.FC<IIcon> = ({
  width = 20,
  height = 16,
  color = "currentColor"
}) => (
  <BackBtnStyles>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.48 6.26044H6.84C6.74213 6.25907 6.64682 6.229 6.56589 6.17397C6.48495 6.11894 6.42195 6.04137 6.38469 5.95087C6.34742 5.86036 6.33754 5.76092 6.35626 5.66485C6.37498 5.56879 6.42148 5.48033 6.49001 5.41044L8.61 3.32044C8.75211 3.18083 8.86498 3.0143 8.94203 2.83059C9.01908 2.64688 9.05876 2.44966 9.05876 2.25044C9.05876 2.05123 9.01908 1.85401 8.94203 1.67029C8.86498 1.48658 8.75211 1.32006 8.61 1.18044L8.14999 0.730441C7.8667 0.457307 7.48852 0.304688 7.095 0.304688C6.70148 0.304688 6.32329 0.457307 6.03999 0.730441L1.24001 5.46044C0.550962 6.14226 0.113771 7.03778 0 8.00044C0.119212 8.96567 0.563605 9.86152 1.25999 10.5404L6.06 15.2704C6.34329 15.5436 6.72148 15.6962 7.11501 15.6962C7.50853 15.6962 7.8867 15.5436 8.17 15.2704L8.63 14.8104C8.77181 14.6717 8.88447 14.506 8.9614 14.3231C9.03832 14.1402 9.07794 13.9438 9.07794 13.7454C9.07794 13.547 9.03832 13.3506 8.9614 13.1678C8.88447 12.9849 8.77181 12.8192 8.63 12.6804L6.50999 10.5904C6.43804 10.5211 6.38853 10.4318 6.36783 10.334C6.34713 10.2362 6.35619 10.1345 6.39386 10.042C6.43153 9.94939 6.49608 9.87024 6.57916 9.81472C6.66225 9.75919 6.76007 9.72984 6.86 9.73044H18.5C18.8978 9.73044 19.2793 9.57241 19.5607 9.2911C19.842 9.0098 20 8.62827 20 8.23044V7.75044C19.9987 7.55261 19.9583 7.35698 19.8811 7.17484C19.8038 6.99269 19.6913 6.82762 19.5501 6.68913C19.4088 6.55064 19.2415 6.44146 19.0579 6.36789C18.8742 6.29432 18.6778 6.2578 18.48 6.26044V6.26044Z"
        fill={color}
      />
    </svg>
  </BackBtnStyles>
);

export { BackBtn };
