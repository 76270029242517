import styled from "styled-components";

export const StyledCheckbox = styled.input`
  clip: rect(0, 0, 0, 0);
  position: absolute;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

export const CheckboxLabel = styled.label`
  line-height: 20px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral["600"]};
`;

export const CheckedWrapper = styled.div<{ value: boolean }>`
  color: ${({ theme, value }) =>
    value ? theme.colors.brand["500"] : theme.colors.periwinkle["500"]};
  cursor: pointer;
  height: 22px;
  width: 22px;
`;
