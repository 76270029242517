import { loginPhases } from "components/web-checkout/login-card/login-card.data";
import { constants } from "constants/constants";
import { usePackageAndTenureSummaryDetails } from "hooks/use-get-package-and-summary-details/usePackageAndTenureSummaryDetails";
import { usePackageById } from "hooks/use-get-package-by-id/use-get-package-by-id";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { setLocalStorage } from "services/local-storage";
import { webCheckoutEventNames } from "services/mixpanel/web-checkout-events";
import { useAuth } from "stores/auth/auth-context";
import {
  couponEventsTracking,
  onChangeTenureClickForPages,
  onClickContinueTenurePopupForPages,
  onCloseTenureChangeModalForPages
} from "utils/pages/utils";
import { isObjectEmpty } from "utils/utils";
import { IUseLogin, IUseLoginProps } from "./use-login.types";

const useLogin = ({ documentReferrer }: IUseLoginProps = {}): IUseLogin => {
  const {
    user,
    user: { isUserLoggedIn }
  } = useAuth();
  const router = useRouter();
  const routerPath = router.pathname;

  const { packageSummaryData, packageId, tenureId, isLoading, coupon_code } =
    usePackageAndTenureSummaryDetails({ router });

  const {
    packageData,
    selectedTenureId,
    setSelectedTenureId,
    isLoading: isPackageByIdLoading
  } = usePackageById({ router });

  useEffect(() => {
    if (packageSummaryData?.data?.tenure_details?.id)
      setSelectedTenureId(packageSummaryData.data.tenure_details.id);
  }, [packageSummaryData]);

  const [prevLink, setPrevLink] = useState("/");
  useEffect(() => {
    if (!isObjectEmpty(user.userData)) {
      router.push({
        pathname: "/auth-redirect",
        query: router.query
      });
    } else {
      const loggedInFrom =
        documentReferrer && documentReferrer.length > 0
          ? documentReferrer
          : window?.location.origin ||
            (process.env.NEXT_PUBLIC_SITE_URL as string);
      setLocalStorage(constants.LOGGED_IN_FROM_PAGE, {
        href: loggedInFrom
      });
      setPrevLink(loggedInFrom);
    }
    return;
  }, []);

  const [isTenureSelectModalOpen, setIsTenureSelectModalOpen] = useState(false);
  const [formPhase, setFormPhase] = useState(loginPhases.generateOTP);

  useEffect(() => {
    if (coupon_code && packageSummaryData && packageId && tenureId) {
      couponEventsTracking({
        coupon_code,
        packageSummaryData,
        packageId,
        tenureId,
        data: packageSummaryData.data,
        user,
        stage:
          formPhase === loginPhases.generateOTP ? "Enter Mobile" : "Enter OTP"
      });
    }
  }, [packageSummaryData?.data?.tenure_details?.valid_coupon]);

  const onChangeTenureClick = (): void => {
    setIsTenureSelectModalOpen(true);
    onChangeTenureClickForPages({
      userData: user,
      packageSummaryData,
      packageId,
      tenureId,
      stage:
        formPhase === loginPhases.generateOTP ? "Enter Mobile" : "Enter OTP",
      eventName: webCheckoutEventNames.changeTenureClicked
    });
  };

  const onClickContinueTenurePopup = (): void => {
    setIsTenureSelectModalOpen(false);
    onClickContinueTenurePopupForPages({
      eventName: webCheckoutEventNames.continueClickedOnTenureSelectPopup,
      stage:
        formPhase === loginPhases.generateOTP ? "Enter Mobile" : "Enter OTP",
      packageId,
      packageSummaryData,
      tenureId,
      userData: user
    });
  };

  const onCloseTenureChangeModal = (): void => {
    setIsTenureSelectModalOpen(false);
    onCloseTenureChangeModalForPages({
      eventName: webCheckoutEventNames.onCloseTenureChangeModal,
      stage:
        formPhase === loginPhases.generateOTP ? "Enter Mobile" : "Enter OTP",
      packageId,
      packageSummaryData,
      tenureId,
      userData: user
    });
  };

  return {
    isUserLoggedIn,
    routerPath,
    setFormPhase,
    formPhase,
    onChangeTenureClick,
    onCloseTenureChangeModal,
    isTenureSelectModalOpen,
    onClickContinueTenurePopup,
    selectedTenureId,
    setSelectedTenureId,
    prevLink,
    isLoading,
    packageId,
    tenureId,
    packageSummaryData,
    packageData,
    isPackageByIdLoading
  };
};

export { useLogin };
