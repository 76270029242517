import React from "react";
import Image from "next/legacy/image";
import LoginBackgroundMobile from "public/assets/login-background-mobile.png";
import LoginBackgroundMobileAlt from "public/assets/login-background-mobile-alt.jpg";
import LoginBackgroundDesktop from "public/assets/login-background-desktop.png";
import {
  BackgroundContainer,
  LoginMobileStyles,
  LoginDesktopStyles,
  ImageText,
  BackButtonMobileContainer,
  ChildrenContainer,
  ButtonDesktopContainer,
  FormContainer,
  ActionContainer
} from "./login-background.styles";
import BackButton from "components/back-button/back-button";

interface ILoginBackgroundProps {
  routerPath: string;
  prevLink?: string;
  children: React.ReactNode;
}

const LoginBackground: React.FC<ILoginBackgroundProps> = ({
  routerPath,
  prevLink,
  children
}) => {
  const isLoginPath = routerPath.includes("/login");

  return (
    <BackgroundContainer>
      <LoginMobileStyles>
        <Image
          layout="intrinsic"
          src={isLoginPath ? LoginBackgroundMobile : LoginBackgroundMobileAlt}
          alt="Login Mobile Background"
        />
        {prevLink && (
          <BackButtonMobileContainer>
            <BackButton prevLink={prevLink} testId="back-login-button" />
          </BackButtonMobileContainer>
        )}
        {isLoginPath && (
          <ImageText>Gapai cita-cita setinggi bintang di langit</ImageText>
        )}
      </LoginMobileStyles>
      <LoginDesktopStyles>
        <Image
          layout="intrinsic"
          src={LoginBackgroundDesktop}
          alt="Login Desktop Background"
          priority
        />
        <ImageText>Gapai cita-cita setinggi bintang di langit</ImageText>
      </LoginDesktopStyles>
      <FormContainer>
        <ChildrenContainer>
          <ActionContainer prevLink={prevLink}>
            {prevLink && (
              <ButtonDesktopContainer>
                <BackButton prevLink={prevLink} testId="back-login-button" />
              </ButtonDesktopContainer>
            )}
          </ActionContainer>

          {children}
        </ChildrenContainer>
      </FormContainer>
    </BackgroundContainer>
  );
};

export { LoginBackground };
