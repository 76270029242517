import styled from "styled-components";
import { breakpoints, sizes } from "styles";
import { Form } from "components/form-component/form/form";
import { Button } from "components/atoms/button/button";

export const CardHeading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
`;
export const CardSubheading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin: 4px 0 12px 0;
  font-weight: normal;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
    line-height: 24px;
  }
`;
export const FormWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const StyledLoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const Disclaimer = styled.p`
  color: ${({ theme }) => theme.colors.neutral[600]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: 16px;
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  margin-top: 16px;
  max-width: ${sizes.loginCardDisclaimerMaxWidth};
`;
export const DisclaimerLink = styled.a`
  color: ${({ theme }) => theme.colors.brand[500]};
  cursor: pointer;
  &:link,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.brand[500]};
  }
`;
export const LoginCTAWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;

  @media ${breakpoints.tablet} {
    margin-top: 16px;
  }
`;
export const LoginCTA = styled(Button)`
  width: 100%;
  @media ${breakpoints.tablet} {
    width: auto;
  }
`;
export const OTPInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;
`;
export const ResendButton = styled.button`
  border: none;
  padding: 0 4px;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brand[500]};
  margin-top: 25px;
  margin-left: 16px;
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
`;

export const CountdownWrapper = styled.div`
  margin-top: 25px;
  margin-left: 16px;
`;

export const CheckboxWrapper = styled.div`
  margin: 15px 0px 0px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    justify-content: flex-start;
  }
`;

export const InfoIconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.periwinkle["600"]};
  cursor: pointer;
  margin-right: 7px;
`;

export const InfoCard = styled.div`
  border-radius: 12px;
  position: absolute;
  color: ${({ theme }) => theme.colors.neutral["900"]};
  background-color: ${({ theme }) => theme.colors.white};
  height: 40px;
  border: ${({ theme }) => `2px solid ${theme.colors.neutral[100]}`};
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  left: 0px;
  margin: 18px 10px 10px 10px;
  box-shadow: 0 0 30px ${({ theme }) => theme.colors.transparent.white10};

  @media ${breakpoints.tablet} {
    width: ${sizes.loginCardInfoWidth.tablet};
    transform: translateX(82%);
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-width: 18px;
    border-style: solid;
    position: relative;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.neutral["100"]} transparent;
    top: -55px;
    transform: translateX(38%);
    @media ${breakpoints.tablet} {
      transform: translateX(85%);
      right: unset;
      position: absolute;
      top: -36px;
    }
  }
`;

export const InfoText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const StyledCheckbox = styled.div`
  width: ${sizes.loginCardCheckboxWidth};
`;
