import { IFormData } from "./login-card.types";

export enum loginPhases {
  generateOTP = "generateOTP",
  validateOTP = "validateOTP"
}

export const formCardData: Record<string, IFormData> = {
  validateOTP: {
    heading: "Masukkan kode verifikasi",
    subHeading: "Kode verifikasinya sudah dikirim lewat SMS ke:"
  },
  generateOTP: {
    heading: "Masuk ke CoLearn",
    subHeading:
      "Untuk mulai, ketik nomor HP yang akan dipakai untuk komunikasi sama CoLearn."
  }
};
