import styled from "styled-components";
import { breakpoints, sizes } from "styles";

export const BackgroundContainer = styled.main`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  @media ${breakpoints.laptop} {
    background-color: ${({ theme }) => theme.colors.neutral["50"]};
    flex-direction: row;
    align-items: center;
  }
`;

export const LoginMobileStyles = styled.div`
  min-width: 100vw;
  background: url("/assets/contact-me-mobile-bg.svg");
  position: relative;
  display: flex;

  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const LoginDesktopStyles = styled.div`
  display: none;
  position: relative;

  @media ${breakpoints.laptop} {
    width: ${sizes.LoginBgWidth};
    min-height: 100vh;
    display: flex;
  }
`;

export const ImageText = styled.p`
  position: absolute;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin: 44px 189px 44px 18px;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["4xl"]};
    margin: 101px 90px 0px 56px;
  }
`;

export const BackButtonMobileContainer = styled.div`
  position: absolute;
  margin: 16px 18px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-grow: 1;
  @media ${breakpoints.laptop} {
    justify-content: center;
    align-items: center;
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-grow: 1;
  @media ${breakpoints.laptop} {
    flex-grow: 0;
    flex-direction: column;
  }
`;

export const ButtonDesktopContainer = styled.div`
  display: none;
  @media ${breakpoints.laptop} {
    display: block;
    margin-bottom: 18px;
  }
`;

export const ActionContainer = styled.div<{ prevLink: string | undefined }>`
  display: flex;
  flex-direction: ${({ prevLink }) => (prevLink ? "row" : "row-reverse")};
  justify-content: space-between;
`;
