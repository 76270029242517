import { Salutation, UserType } from "types/common/common.types";

export enum UserClaim {
  FREE = "free",
  PAID = "paid"
}

export interface ILoginEligibilityResponse {
  eligible: boolean;
  reason: string | null;
}

export interface IGenerateOTPPayload {
  phone: string;
}
export interface IValidateOTPPayload {
  otp: string;
  notification_consent: boolean;
}

interface IGenerateOTPTokenData {
  token: string;
  expiresIn: string;
}

interface IResponseMeta {
  length: number;
  took: number;
  total: number;
}

export interface IGenerateOTPResponse {
  data: IGenerateOTPTokenData;
  errors: string | null;
  message: string;
  meta: IResponseMeta;
}

interface IBaseUser {
  id: string;
  email: string;
  role: UserType;
  phone_number: string;
  country_code: string;
}
interface IUserWithAuth extends IBaseUser {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export interface IStudentUser extends IBaseUser {
  avatar_url?: string;
  full_name: string;
  grade: string;
  class_type: string;
  stream: string;
  student_id: string;
  onboarded_at: string;
  curriculum: string;
}
export interface IParentUser extends IUserWithAuth {
  parent_id: string;
  salutation: Salutation;
  name: string;
  students: IStudentUser[];
}
export interface IPrimaryUser extends IStudentUser, IUserWithAuth {
  student_id: string;
}
export interface IValidateOTPResponse {
  data: {
    parent_user: IParentUser;
    student_users: IStudentUser[];
    primary_user: IPrimaryUser;
  };
  errors: string | null;
  message: string;
  meta: IResponseMeta;
}

export interface IPrimaryAuthData {
  parent_user?: IParentUser;
  student_users?: IStudentUser[];
  primary_user: IPrimaryUser;
}

export interface IAuthCookieStudent {
  student_users?: Array<IStudentUser>;
}

export type IAuthCookie = IPrimaryAuthData;

export interface IAuthCookieParent {
  parent_user?: IParentUser;
}
