import React, { useState } from "react";

import { CardHeading, CardSubheading, FormWrapper } from "./login-card.styles";
import { formCardData, loginPhases } from "./login-card.data";

import { GenerateOTP } from "./forms/generate-otp";
import { ValidateOTP } from "./forms/validate-otp";
import { Card } from "styles/pages/web-checkout.styles";
import { ILoginCard } from "./login-card.types";

export const LoginCard: React.FC<ILoginCard> = ({
  setFormPhase,
  formPhase,
  summaryPackageData
}) => {
  const [validateOTPToken, setValidateOTPToken] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <Card>
      <CardHeading>{formCardData[formPhase].heading}</CardHeading>
      <CardSubheading>{formCardData[formPhase].subHeading}</CardSubheading>
      <FormWrapper>
        {formPhase === loginPhases.generateOTP ? (
          <GenerateOTP
            setToken={setValidateOTPToken}
            setFormPhase={setFormPhase}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
          />
        ) : (
          <ValidateOTP
            summaryPackageData={summaryPackageData}
            setFormPhase={setFormPhase}
            token={validateOTPToken}
            setToken={setValidateOTPToken}
            phoneNumber={phoneNumber}
          />
        )}
      </FormWrapper>
    </Card>
  );
};
