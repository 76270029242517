import { Modal } from "components/modal/modal";
import { ModalSize } from "components/modal/modal.types";
import { SummaryPageCard } from "components/web-checkout/summary-page-card/summary-page-card";
import { TenurePickerCard } from "components/web-checkout/tenure-picker-card/tenure-picker-card";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { SummaryCardWrapper } from "styles/pages/web-checkout.styles";
import { ICheckoutSummaryCardProps } from "./checkout-summary-card.types";

export const CheckoutSummaryCard: React.FC<ICheckoutSummaryCardProps> = ({
  packageSummaryData,
  onChangeTenureClick,
  onCloseTenureChangeModal,
  isTenureSelectModalOpen,
  packageData,
  selectedTenureId,
  onClickContinueTenurePopup,
  setSelectedTenureId
}) => {
  return packageSummaryData?.data && packageSummaryData?.meta ? (
    <SummaryCardWrapper>
      <SummaryPageCard
        {...packageSummaryData?.data?.tenure_details}
        name={packageSummaryData?.data?.name}
        subjects={packageSummaryData?.data?.subjects}
        changeTenureAction={() => onChangeTenureClick()}
        mediaUrl={packageSummaryData?.meta?.media_base_url}
      />
      <Modal
        size={ModalSize.ExtraWide}
        onClose={() => onCloseTenureChangeModal()}
        isOpen={isTenureSelectModalOpen}>
        {packageData && selectedTenureId && (
          <TenurePickerCard
            summaryPackageData={packageSummaryData?.data}
            layout={"modal"}
            closeModal={() => onClickContinueTenurePopup()}
            selectedTenureId={selectedTenureId}
            setSelectedTenureId={setSelectedTenureId}
            packageDetails={packageData.data}
            mediaUrl={packageSummaryData?.meta?.media_base_url}
          />
        )}
      </Modal>
    </SummaryCardWrapper>
  ) : (
    <Skeleton height={650} width={380} />
  );
};
