import React, { useRef } from "react";
import { MobileInput } from "components/form-component/mobile-input/mobile-input";
import Link from "next/link";

import { useGenerateOTP } from "../hooks/use-generate-otp";

import {
  Disclaimer,
  DisclaimerLink,
  LoginCTA,
  LoginCTAWrapper,
  StyledLoginForm,
  CheckboxWrapper,
  InfoIconWrapper,
  InfoCard,
  InfoText,
  StyledCheckbox
} from "../login-card.styles";
import {
  IGenerateOTPProps,
  PhoneNumberEligibilityStages
} from "../login-card.types";
import { Checkbox } from "components/atoms/checkbox/checkbox";
import { InfoIcon } from "components/icons/info";
import { useHover } from "hooks/use-hover/use-hover";
import dynamic from "next/dynamic";

const MissingActivePackagePopup = dynamic(
  () =>
    import(
      "../components/missing-active-package-popup/missing-active-package-popup"
    )
);
const PhoneNumberEligibilityLoadingPopup = dynamic(
  () =>
    import(
      "../components/phone-eligibility-loading-popup/phone-eligibility-loading-popup"
    )
);
const LoginSupportRequestFormPopup = dynamic(
  () =>
    import(
      "../components/login-support-request-form/login-support-request-form"
    )
);

const GenerateOTP: React.FC<IGenerateOTPProps> = ({
  setToken,
  setFormPhase,
  setPhoneNumber,
  phoneNumber
}) => {
  const {
    handleSubmit,
    onSubmit,
    isValid,
    control,
    phoneNumberEligibilityStage,
    setPhoneNumberEligibilityStage
  } = useGenerateOTP({
    setToken,
    setFormPhase,
    setPhoneNumber,
    phoneNumber
  });

  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const renderPhoneNumberEligibilityStage = () => {
    switch (phoneNumberEligibilityStage) {
      case PhoneNumberEligibilityStages.loading: {
        return <PhoneNumberEligibilityLoadingPopup />;
      }
      case PhoneNumberEligibilityStages.missingActivePackage: {
        return (
          <MissingActivePackagePopup
            onCancel={() => setPhoneNumberEligibilityStage(null)}
            onProceed={() =>
              setPhoneNumberEligibilityStage(
                PhoneNumberEligibilityStages.supportRequestForm
              )
            }
          />
        );
      }
      case PhoneNumberEligibilityStages.supportRequestForm: {
        return (
          <LoginSupportRequestFormPopup
            onCancel={() => setPhoneNumberEligibilityStage(null)}
            onProceed={() => setPhoneNumberEligibilityStage(null)}
            userPhoneNumber={phoneNumber}
          />
        );
      }
    }
  };

  return (
    <>
      <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
        <MobileInput
          name={"phone"}
          label={"Nomor HP"}
          placeholder={"Ketik nomor HP di sini"}
          control={control}
        />

        <CheckboxWrapper>
          <StyledCheckbox>
            <Checkbox
              control={control}
              name="isWhatsappConsentGiven"
              label="Kirimin jadwal dan info lainnya lewat WhatsApp."
              testId="whatsapp-login-checkbox"
            />
          </StyledCheckbox>

          <InfoIconWrapper ref={hoverRef}>
            <InfoIcon />

            {isHover && (
              <InfoCard>
                <InfoText>
                  Khusus SD, CoLearn hanya kirim notifikasi lewat aplikasi dan
                  nggak lewat WhatsApp.
                </InfoText>
              </InfoCard>
            )}
          </InfoIconWrapper>
        </CheckboxWrapper>

        <Disclaimer>
          Dengan melanjutkan, saya setuju dengan{" "}
          <Link href="/ketentuan-layanan" passHref legacyBehavior>
            <DisclaimerLink data-testid="tos-login-button">
              Ketentuan Layanan
            </DisclaimerLink>
          </Link>{" "}
          dan{" "}
          <Link href="/kebijakan-privasi" passHref legacyBehavior>
            <DisclaimerLink data-testid="privacy-policy-login-button">
              Kebijakan Privasi
            </DisclaimerLink>
          </Link>{" "}
        </Disclaimer>
        <LoginCTAWrapper>
          <LoginCTA
            type="submit"
            disabled={!isValid}
            variant="primary"
            data-testid="next-login-button"
          >
            Lanjut
          </LoginCTA>
        </LoginCTAWrapper>
      </StyledLoginForm>
      {renderPhoneNumberEligibilityStage()}
    </>
  );
};

export { GenerateOTP };
