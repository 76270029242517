import {
  Control,
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit
} from "react-hook-form";
import { IPackageAndTenureSummaryDetails } from "services/api/packages/packageTenureSummaryDetails.types";
import { loginPhases } from "./login-card.data";

export type GenerateOTPFormValues = {
  phone: string;
  isWhatsappConsentGiven: boolean;
};
export type ValidateOTPFormValues = {
  otp: string;
  phone: string;
};

export interface IFormData {
  heading: string;
  subHeading: string;
}

export type IUseGenerateOTP<T extends FieldValues> = {
  handleSubmit: UseFormHandleSubmit<GenerateOTPFormValues>;
  onSubmit: SubmitHandler<GenerateOTPFormValues>;
  isValid: boolean;
  control: Control<T>;
  phoneNumberEligibilityStage: PhoneNumberEligibilityStages | null;
  setPhoneNumberEligibilityStage: React.Dispatch<
    React.SetStateAction<PhoneNumberEligibilityStages | null>
  >;
};
export type IUseValidateOTP<T extends FieldValues> = {
  handleSubmit: UseFormHandleSubmit<ValidateOTPFormValues>;
  onSubmit: SubmitHandler<ValidateOTPFormValues>;
  isValid: boolean;
  control: Control<T>;
  isOTPTimerActive: boolean;
  enableResendOTP: () => void;
  resendOTPAction: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
  resendOTPCount: number;
  editNumber: () => void;
  isVerificationLoading: boolean;
};
export interface IGenerateOTPProps {
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setFormPhase: React.Dispatch<React.SetStateAction<loginPhases>>;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
}

export interface IValidateOTPProps {
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setFormPhase: React.Dispatch<React.SetStateAction<loginPhases>>;
  phoneNumber: string;
  token: string;
  summaryPackageData?: IPackageAndTenureSummaryDetails;
}

export interface ILoginCard {
  setFormPhase: React.Dispatch<React.SetStateAction<loginPhases>>;
  formPhase: loginPhases;
  summaryPackageData: IPackageAndTenureSummaryDetails | undefined;
}

export enum PhoneNumberEligibilityStages {
  loading = "loading",
  missingActivePackage = "missingActivePackage",
  supportRequestForm = "supportRequestForm"
}
