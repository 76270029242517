import React from "react";
import { CheckboxProps } from "./checkbox.types";
import { useController } from "react-hook-form";
import {
  StyledCheckbox,
  CheckboxDiv,
  CheckedWrapper,
  CheckboxLabel
} from "./checkbox.styles";
import { FieldError } from "components/form-component/field-error/field-error";
import { Checked } from "../../icons/checkbox/checked";
import { UnChecked } from "../../icons/checkbox/unchecked";

const Checkbox = <T extends Record<string, string | boolean>>({
  label,
  name,
  control,
  onChange,
  testId
}: CheckboxProps<T>): JSX.Element => {
  const {
    field,
    fieldState,
    fieldState: { error }
  } = useController({
    control,
    name
  });

  return (
    <label>
      <CheckboxDiv>
        {/* @ts-expect-error */}
        <StyledCheckbox
          type="checkbox"
          data-testid={testId}
          checked={Boolean(field.value)}
          {...field}
          {...fieldState}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            if (onChange) {
              onChange();
            }
          }}
        />

        <CheckedWrapper value={Boolean(field.value)}>
          {field.value ? <Checked /> : <UnChecked />}
        </CheckedWrapper>

        {label && <CheckboxLabel>{label}</CheckboxLabel>}
      </CheckboxDiv>
      {error?.message && <FieldError message={error.message} />}
    </label>
  );
};

export { Checkbox };
