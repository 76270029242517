import React from "react";
import type { IIcon } from "../icons.types";

const Checked: React.FC<IIcon> = ({
  height = 20,
  width = 20,
  color = "currentColor"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM17.0607 10.0607C17.6464 9.47487 17.6464 8.52513 17.0607 7.93934C16.4749 7.35355 15.5251 7.35355 14.9393 7.93934L10.5 12.3787L9.06066 10.9393C8.47487 10.3536 7.52513 10.3536 6.93934 10.9393C6.35355 11.5251 6.35355 12.4749 6.93934 13.0607L9.43934 15.5607C10.0251 16.1464 10.9749 16.1464 11.5607 15.5607L17.0607 10.0607Z"
      fill={color}
    />
  </svg>
);

export { Checked };
