export * from "./tanya/tanyaService.types";
export * from "./packages/packageList.types";
export * from "./slot-selection/slotSelection.types";
export * from "./auth/authService.types";

export interface IApiMeta {
  length: number;
  took: number;
  total: number;
}

export interface IApiError {
  data: string | null;
  errors: {
    status?: number;
    code: string;
    service: string;
  };
  message: string;
  meta: IApiMeta;
}
