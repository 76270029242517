export type VideoAPIData = {
  id: string;
  questionId: string;
  youtube_url?: string;
  subjects: Array<string>;
  grades: Array<string>;
  sections: Array<string>;
  chapters: Array<string>;
  cover_image: string;
  topics: Array<string>;
  video_url?: string;
  title: string;
  slug: string;
  slugs: ISlugs;
  upload_date: string;
  duration: string;
  transcript: string | null;
  duration_in_seconds: number;
  recommendations?: {
    data: VideoAPIData[];
    filters: Record<string, string>;
  };
  similar_questions?: Array<SimilarQuestion>;
  search_id?: string;
  curriculum?: VideoCurriculum[] | null;
  thumbnail_urls?: string[];
  part_of_experiment?: string;
  // upvote_count?: number;
};

export type SimilarQuestion = {
  id: string;
  subjects: Array<string>;
  grades: Array<string>;
  sections: Array<string>;
  chapters: Array<string>;
  topics: Array<string>;
  title: string;
  slug: string;
  video_url: string;
  youtube_url: null;
  question_id: string;
};
export type VideoCurriculum =
  | "Kurikulum Merdeka"
  | "K-13"
  | "K-13 revisi"
  | "K-13 Revisi"
  | "K-2000"
  | "K-2004"
  | "K-2006"
  | "K-2006 and K13"
  | "K-22"
  | "K-94"
  | "K-94 Revisi"
  | "KBK"
  | "KM"
  | "KTSP"
  | "KTSP 2006";

export type ISlugs = {
  subjects: Array<string>;
  grades: Array<string>;
  sections: Array<string>;
  chapters: Array<string>;
  topics: Array<string>;
  title: string;
};

export type AllQuestionsMetaData = {
  page: number;
  per_page: string;
  total_pages: number;
};

export type IGetVideoDetailsResponse = VideoAPIData;

export interface IGetVideoRecommendationsData {
  data: VideoAPIData[];
}

export interface IGetQuestions {
  data: VideoAPIData[];
  filters: Record<string, unknown>;
  meta: AllQuestionsMetaData;
}

export interface IQuestionParams {
  grade?: string;
  subject?: string;
  topic?: string;
  chapter?: string;
  section?: string;
  chapter_slug?: string;
  section_slug?: string;
  topic_slug?: string;
}

export type GetPushToAppDeeplinkParams = {
  campaign: string;
  channel: string;
  feature: string;
  banner_experiment_variant: string;
  questionId: string;
};

export type IGetPushToAppDeeplink = {
  deep_link_url: string;
};

export type IGetVideoSlugs = {
  id: string;
  slug: string;
};

export interface IGetSubscriptionPaymentsResponse {
  data: {
    subscription_payments: {
      data: SubscriptionPayment[];
    };
  };
}

export interface SubscriptionPayment {
  id: string;
  user_id: string;
  product_id: string;
  payable_type: string;
  payable_id: string;
  status: SubscriptionPaymentStatus;
  platform: string;
  amount: number;
  currency: string;
  metadata: SubscriptionPaymentMetaData;
  created_at: string;
  updated_at: string;
  payable: {
    id: string;
    plan_id: string;
    subscription_payment_id: string;
    cycle_id: string;
    cycle_number: number;
    status: string;
    metadata: {
      orignal_payment_id: string;
    };
    checkout_url: string;
    created_at: string;
    updated_at: string;
    cancelled_at: null;
  };
}

export enum SubscriptionPaymentStatus {
  SUCCESS = "success",
  INITIATED = "initiated",
  FAILED = "failed"
}

export interface SubscriptionPaymentMetaData {
  payment_initiated_video_page: string;
  openedFrom?: string | null;
}

export interface IGetSubscriptionPaymentById {
  data: {
    subscription_payment: SubscriptionPayment;
  };
}
