import React, { useRef } from "react";
import { Label } from "../label/label";
import { OTPInputDiv, OTPDiv } from "./otp-input.styles";
import { OTPInputProps } from "./otp-input.types";
import { FieldPath, useController } from "react-hook-form";
import { FieldError } from "../field-error/field-error";
import { OTPField } from "./otp-field";
import { constants } from "constants/constants";

const inputFields: Record<string, string> = {};

const OTPInput = <T extends Record<string, string>>({
  label,
  control,
  name,
  numInputs
}: OTPInputProps<T>): JSX.Element => {
  const numberOfFields = Array(numInputs).fill(0);

  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name
  });

  const inputRef = useRef<HTMLInputElement[]>([]);

  const { value: fieldValue } = field;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, name } = e.target;
    inputFields[name] = value;
    field.onChange(Object.values(inputFields).join(""));

    const [, fieldIndex] = name.split("-");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < numberOfFields.length - 1) {
        const nextSibling: HTMLElement | null = inputRef.current[
          Number(fieldIndex)
        ].nextSibling as HTMLElement;

        if (nextSibling) {
          nextSibling.focus();
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === constants.key.BACKSPACE) {
      const name = (e.target as HTMLInputElement).name;
      const [, fieldIndex] = name.split("-");
      const previousSibling: HTMLElement | null = inputRef.current[
        Number(fieldIndex)
      ].previousSibling as HTMLElement;
      if (previousSibling) {
        previousSibling.focus();
      }
    }
  };

  return (
    <OTPInputDiv>
      {label && <Label id={field.name}>{label}</Label>}

      <OTPDiv>
        {numberOfFields.map((_, index) => {
          return (
            <OTPField
              key={index}
              name={`input-${index}` as FieldPath<T>}
              onChange={handleChange}
              onKeyUp={handleKeyDown}
              control={control}
              testId={`OTP-field-${index}`}
              fieldRef={(el: HTMLInputElement) => {
                if (el) {
                  inputRef.current[index] = el;
                }
              }}
              defaultValue={fieldValue[index] || ""}
            />
          );
        })}
      </OTPDiv>

      {error?.message && <FieldError message={error.message} />}
    </OTPInputDiv>
  );
};

export { OTPInput };
