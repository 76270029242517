import React from "react";
import { IIcon } from "./icons.types";

const InfoIcon = ({
  height = 18,
  width = 18,
  color = "currentColor"
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00033 0.0834961C7.90598 0.0834961 6.82234 0.29904 5.8113 0.717829C4.80025 1.13662 3.88159 1.75045 3.10777 2.52427C1.54497 4.08707 0.666992 6.20669 0.666992 8.41683C0.666992 10.627 1.54497 12.7466 3.10777 14.3094C3.88159 15.0832 4.80025 15.697 5.8113 16.1158C6.82234 16.5346 7.90598 16.7502 9.00033 16.7502C11.2105 16.7502 13.3301 15.8722 14.8929 14.3094C16.4557 12.7466 17.3337 10.627 17.3337 8.41683C17.3337 6.20669 16.4557 4.08707 14.8929 2.52427C13.3301 0.961463 11.2105 0.0834961 9.00033 0.0834961ZM10.2503 13.2502C10.2503 13.5154 10.145 13.7697 9.95743 13.9573C9.7699 14.1448 9.51554 14.2502 9.25033 14.2502H8.75033C8.48511 14.2502 8.23076 14.1448 8.04322 13.9573C7.85568 13.7697 7.75033 13.5154 7.75033 13.2502V8.58349C7.75033 8.31828 7.85568 8.06392 8.04322 7.87639C8.23076 7.68885 8.48511 7.5835 8.75033 7.5835H9.25033C9.51554 7.5835 9.7699 7.68885 9.95743 7.87639C10.145 8.06392 10.2503 8.31828 10.2503 8.58349V13.2502ZM9.00033 6.12516C8.61355 6.12516 8.24262 5.97152 7.96913 5.69803C7.69564 5.42454 7.54199 5.0536 7.54199 4.66683C7.54199 4.28006 7.69564 3.90912 7.96913 3.63563C8.24262 3.36214 8.61355 3.2085 9.00033 3.2085C9.3871 3.2085 9.75803 3.36214 10.0315 3.63563C10.305 3.90912 10.4587 4.28006 10.4587 4.66683C10.4587 5.0536 10.305 5.42454 10.0315 5.69803C9.75803 5.97152 9.3871 6.12516 9.00033 6.12516Z"
        fill={color}
      />
    </svg>
  );
};

export { InfoIcon };
