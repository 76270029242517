import styled from "styled-components";
import { breakpoints } from "styles";

export const BackButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const BackButtonText = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.black};

  @media ${breakpoints.laptop} {
    color: ${({ theme }) => theme.colors.black};
  }
`;
