import React from "react";
import { Timer } from "./countdown-timer.styles";
import { ICountdownTimerProps } from "./countdown-timer.types";
import { useContdownTimer } from "./use-countdown-timer";
const CountdownTimer: React.FC<ICountdownTimerProps> = ({
  minutes = 0,
  seconds,
  onReset
}) => {
  const { mins, secs } = useContdownTimer({ minutes, seconds, onReset });

  return (
    <Timer>{`${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`}</Timer>
  );
};

export { CountdownTimer };
