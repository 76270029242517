import React from "react";
import Link from "next/link";

import { BackButtonContainer, BackButtonText } from "./back-button.styles";

import trackEvent from "services/mixpanel/mixpanel";
import { eventNames } from "services/mixpanel/events";
import {
  createSuperPropertiesForTrackingEvent,
  getSourceInfoEvent
} from "utils/mixpanel/mixpanel-utils";
import { useAuth } from "stores/auth/auth-context";
import { useGetCurrentPlatform } from "hooks/use-media-query/use-get-currrent-platform";
import { BackBtn } from "components/icons/back-btn";

interface IBackButtonProps {
  prevLink: string;
  testId?: string;
}

const TanyaBackButton: React.FC<IBackButtonProps> = ({ prevLink, testId }) => {
  const { user } = useAuth();
  const currentPlatform = useGetCurrentPlatform();

  const handleBackButtonClick = () => {
    trackEvent({
      eventName: eventNames.backClickedOnRegisterScreen,
      payload: {
        ...createSuperPropertiesForTrackingEvent({ useAuthData: user }),
        sourceInfo: getSourceInfoEvent(currentPlatform)
      }
    });
  };

  return (
    <BackButtonContainer data-testid={testId}>
      <Link href={prevLink} passHref legacyBehavior>
        <a onClick={() => handleBackButtonClick()}>
          <BackBtn />
        </a>
      </Link>
      <BackButtonText>Kembali</BackButtonText>
    </BackButtonContainer>
  );
};

export default TanyaBackButton;
