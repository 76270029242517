import { useEffect, useState } from "react";
import { useAuth } from "stores/auth/auth-context";
import { loginPhases } from "../login-card.data";
import {
  GenerateOTPFormValues,
  IGenerateOTPProps,
  IUseGenerateOTP,
  PhoneNumberEligibilityStages
} from "../login-card.types";
import { useForm, SubmitHandler } from "react-hook-form";
import { generateOTP, loginEligibility } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, bool, InferType } from "yup";
import { IApiError, UserClaim } from "services/api/api.types";
import { useToast } from "components/atoms/toast/toast-context";
import { phoneRegExp } from "constants/constants";
import trackEvent from "services/mixpanel/mixpanel";
import { eventNames } from "services/mixpanel/events";
import { constants } from "constants/constants";
import {
  createSuperPropertiesForTrackingEvent,
  getSourceInfoEvent
} from "utils/mixpanel/mixpanel-utils";
import { parseAPIErrorResponse } from "utils/utils";
import { useGetCurrentPlatform } from "hooks/use-media-query/use-get-currrent-platform";

const FormSchema = object().shape({
  phone: string()
    .required("Mohon lengkapi nomor HP kamu")
    .matches(phoneRegExp, "Masukin nomor HP yang aktif, ya.")
    .min(8, "Masukin minimal 8 angka, ya.")
    .max(13, "Hmm... nomornya kepanjangan, nih."),
  isWhatsappConsentGiven: bool().oneOf([true, false]).required()
});
export const useGenerateOTP = ({
  setToken,
  setFormPhase,
  setPhoneNumber
}: IGenerateOTPProps): IUseGenerateOTP<GenerateOTPFormValues> => {
  const { addToast } = useToast();
  const [phoneNumberEligibilityStage, setPhoneNumberEligibilityStage] =
    useState<PhoneNumberEligibilityStages | null>(null);

  const currentPlatform = useGetCurrentPlatform();

  const {
    user,
    user: { isWhatsappConsentGiven, setIsWhatsappConsentGiven }
  } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<InferType<typeof FormSchema>>({
    defaultValues: {
      phone: "",
      isWhatsappConsentGiven
    },
    resolver: yupResolver(FormSchema),
    mode: "onChange",
    delayError: constants.DELAY_ERROR
  });

  useEffect(() => {
    trackEvent({
      eventName: eventNames.mobileNumberEnteredScreenViewed,
      payload: {
        ...createSuperPropertiesForTrackingEvent({ useAuthData: user })
      }
    });
  }, [user]);

  const isPhoneNumberEligibleToLogin = async (phoneNumber: string) => {
    try {
      setPhoneNumberEligibilityStage(PhoneNumberEligibilityStages.loading);

      // Check if the phone number is eligible to login
      const { eligible: isEligible } = await loginEligibility({
        phone_number: phoneNumber,
        user_claim: UserClaim.PAID
      });

      if (isEligible) {
        // close the eligibility stages related modal
        setPhoneNumberEligibilityStage(null);
      } else {
        // proceed with the eligibility stages flow
        setPhoneNumberEligibilityStage(
          PhoneNumberEligibilityStages.missingActivePackage
        );
      }
      return isEligible;
    } catch (err) {
      addToast({
        heading: "Maaf CoFriends, lagi ada error. Coba lagi, ya.",
        variant: "error"
      });
    }
  };

  const onSubmit: SubmitHandler<GenerateOTPFormValues> = async (data) => {
    const phoneNumber = data.phone;
    const currentPlatformSource = getSourceInfoEvent(currentPlatform);

    try {
      setPhoneNumber(phoneNumber);
      const isEligibleToLogin = await isPhoneNumberEligibleToLogin(phoneNumber);

      if (!isEligibleToLogin) {
        // prevent sending otp if not eligible to login
        return;
      }

      trackEvent({
        eventName: eventNames.mobileNumberEntered,
        payload: {
          sourceInfo: currentPlatformSource,
          incomingSource: "website",
          eventSource: "website",
          whatsAppOptIn: null,
          whatsAppConsent: isWhatsappConsentGiven,
          userType: null,
          userRole: null,
          stream: null,
          referrer: typeof document !== "undefined" ? document.referrer : null,
          onboardingDate: null,
          name: null,
          mobile: phoneNumber,
          isTablet: null,
          isOnboarded: null,
          id: null,
          grade: null,
          focusCount: null,
          focus: null,
          email: null,
          curriculum: null,
          class: null,
          platformSource: "Webcheckoutlogin"
        }
      });
      const res = await generateOTP(phoneNumber, currentPlatformSource);
      setIsWhatsappConsentGiven(data.isWhatsappConsentGiven);
      setToken(res.data.token);
      setFormPhase(loginPhases.validateOTP);
      addToast({
        heading: "Yay, kode verifikasi udah dikirim.",
        variant: "success"
      });
    } catch (err) {
      const error = parseAPIErrorResponse(err);

      const { message } = error as IApiError;
      addToast({ heading: message, variant: "error" });
    }
  };

  return {
    handleSubmit,
    onSubmit,
    isValid,
    control,
    phoneNumberEligibilityStage,
    setPhoneNumberEligibilityStage
  };
};
