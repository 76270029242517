import React from "react";
import { Path, PathValue, useController } from "react-hook-form";
import { OTPInputField } from "./otp-input.styles";
import { OTPFieldProps } from "./otp-input.types";

const OTPField = <T extends Record<string, string | number>>({
  control,
  name,
  onChange,
  onKeyUp,
  defaultValue,
  fieldRef,
  testId
}: OTPFieldProps<T>): JSX.Element => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: defaultValue as PathValue<T, Path<T>>
  });

  return (
    <OTPInputField
      maxLength={1}
      type="text"
      inputMode="numeric"
      data-testid={testId}
      {...fieldState}
      {...field}
      ref={fieldRef}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
        }
        field.onChange(e.target.value);
      }}
      onKeyUp={onKeyUp}
    />
  );
};

export { OTPField };
