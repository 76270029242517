import {
  createSuperPropertiesForTrackingEvent,
  getSourceInfoEvent
} from "./../../../../utils/mixpanel/mixpanel-utils";
import { useAuth } from "stores/auth/auth-context";
import { useEffect, useState } from "react";
import { loginPhases } from "../login-card.data";
import {
  ValidateOTPFormValues,
  IUseValidateOTP,
  IValidateOTPProps
} from "../login-card.types";
import { useForm, SubmitHandler } from "react-hook-form";
import { generateOTP } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType } from "yup";
import { signIn, getSession } from "next-auth/react";
import { useToast } from "components/atoms/toast/toast-context";
import { IApiError } from "services/api/api.types";
import { INextAuthResponse } from "types/next-auth";
import { useRouter } from "next/router";
import trackEvent, {
  identifyUser,
  registerSuperProperties,
  setUserProperties
} from "services/mixpanel/mixpanel";
import { eventNames } from "services/mixpanel/events";
import { webCheckoutEventNames } from "services/mixpanel/web-checkout-events";
import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import { hasMultipleProfiles } from "utils/utils";
import { UserType } from "types/common/common.types";
import { constants, phoneRegExp } from "constants/constants";
import { useGetCurrentPlatform } from "hooks/use-media-query/use-get-currrent-platform";
import { useKelasLiveContext } from "stores/kelas-live/kelas-live-context";
import * as Sentry from "@sentry/nextjs";

const FormSchema = object().shape({
  otp: string()
    .required()
    .test(
      "len",
      "Kodenya harus 6 angka. Cek lagi, ya.",
      (val) => val?.length === 6
    ),
  phone: string()
    .required("Mohon lengkapi nomor HP kamu")
    .matches(phoneRegExp, "Masukin nomor HP yang aktif, ya.")
    .min(8, "Masukin minimal 8 angka, ya.")
    .max(13, "Hmm... nomornya kepanjangan, nih.")
});

export const useValidateOTP = ({
  setFormPhase,
  token,
  setToken,
  phoneNumber,
  summaryPackageData
}: IValidateOTPProps): IUseValidateOTP<ValidateOTPFormValues> => {
  const router = useRouter();
  const { addToast } = useToast();

  const [isOTPTimerActive, setIsOTPTimerActive] = useState(true);
  const [isVerificationLoading, setVerificationLoading] = useState(false);
  const [resendOTPCount, setResendOTPCount] = useState(0);

  const { user } = useAuth();
  const { isWhatsappConsentGiven, setUserData } = user;
  const { userSubscription } = useKelasLiveContext();

  const currentPlatform = useGetCurrentPlatform();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<InferType<typeof FormSchema>>({
    defaultValues: {
      otp: "",
      phone: phoneNumber
    },
    resolver: yupResolver(FormSchema),
    mode: "onChange",
    delayError: constants.DELAY_ERROR
  });

  useEffect(() => {
    trackEvent({
      eventName: eventNames.otpEnterScreenViewed,
      payload: {
        ...createSuperPropertiesForTrackingEvent({ useAuthData: user })
      }
    });
  }, []);

  const onSubmit: SubmitHandler<ValidateOTPFormValues> = async (data) => {
    const { otp } = data;
    setVerificationLoading(true);
    signIn("credentials", {
      otp,
      token,
      isWhatsappConsentGiven,
      redirect: false,
      phoneNumber
    }).then(async (res: unknown) => {
      setVerificationLoading(false);
      const { error, ok } = res as INextAuthResponse;
      if (error) {
        const payload = createPayloadForWebCheckoutTrackingEvent({
          useAuthData: user,
          Class: true,
          level: true,
          packageType: summaryPackageData?.name || null,
          tenureValue:
            summaryPackageData?.tenure_details.tenure_text.text || null
        });
        trackEvent({
          eventName: webCheckoutEventNames.invalidOTPEntered,
          payload
        });
        try {
          const apiError: unknown = JSON.parse(error);
          const { message } = apiError as IApiError;
          return addToast({
            heading: message,
            variant: "error"
          });
        } catch (error) {
          Sentry.captureException(error);
          return addToast({
            heading: constants.DEFAULT_UNEXPECTED_ERROR_MESSAGE,
            variant: "error"
          });
        }
      }
      if (ok) {
        trackEvent({
          eventName: eventNames.correctOTPEntered,
          payload: {
            incomingSource: "website",
            eventSource: "website",
            whatsAppOptIn: null,
            whatsAppConsent: isWhatsappConsentGiven,
            userType: null,
            userRole: null,
            stream: null,
            referrer:
              typeof document !== "undefined" ? document.referrer : null,
            onboardingDate: null,
            name: null,
            mobile: phoneNumber,
            isTablet: null,
            isOnboarded: null,
            Id: null,
            grade: null,
            focusCount: null,
            focus: null,
            email: null,
            curriculum: null,
            class: null,
            platformSource: "Webcheckoutlogin"
          }
        });

        const session = await getSession();

        if (session && session.user) {
          setUserData(session.user);
          const { primary_user } = session.user;

          if (primary_user.role === UserType.STUDENT) {
            if (!hasMultipleProfiles(session.user)) {
              identifyUser(primary_user.id);

              // Fetch subscription status of the user
              if (primary_user?.access_token)
                userSubscription.fetchSubscriptionStatus(
                  primary_user.access_token
                );
            }
            registerSuperProperties({
              incomingSource: "website",
              isOnboarded: true,
              userRole: primary_user.role,
              whatsAppConsent: isWhatsappConsentGiven,
              name: primary_user.full_name,
              class: primary_user.class_type,
              email: primary_user.email,
              grade: primary_user.grade,
              isTablet: null,
              id: primary_user.id,
              studentId: primary_user.student_id,
              stream: primary_user.stream,
              onBoardingDate: primary_user.onboarded_at,
              mobile: primary_user.phone_number
            });
            setUserProperties({
              name: primary_user.full_name,
              email: primary_user.email,
              id: primary_user.id,
              mobile: primary_user.phone_number,
              whatsAppConsent: isWhatsappConsentGiven,
              onBoardingDate: primary_user.onboarded_at,
              isOnboarded: true,
              grade: primary_user.grade,
              stream: primary_user.stream,
              class: primary_user.class_type
            });
            Sentry.setUser({
              id: primary_user.id,
              email: primary_user.email,
              username: primary_user.student_id,
              name: primary_user.full_name
            });
            trackEvent({
              eventName: eventNames.studentLogin,
              payload: {
                eventSource: "website",
                referrer: document.referrer
              }
            });
          }
        }

        router.push({
          pathname: "/auth-redirect",
          query: router.query
        });
      }
    });
  };

  const resendOTPAction = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const currentPlatformSource = getSourceInfoEvent(currentPlatform);
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user,
      Class: true,
      level: true,
      packageType: summaryPackageData?.name || null,
      tenureValue: summaryPackageData?.tenure_details.tenure_text.text || null
    });
    trackEvent({
      eventName: webCheckoutEventNames.resendOTPClicked,
      payload
    });

    reset();
    setIsOTPTimerActive(true);
    setResendOTPCount((prevResendOTPCount) => prevResendOTPCount + 1);
    const res = await generateOTP(phoneNumber, currentPlatformSource);
    setToken(res.data.token);
    setFormPhase(loginPhases.validateOTP);
    addToast({
      heading: "Yay, kode verifikasi udah dikirim.",
      variant: "success"
    });
  };

  const editNumber = () => {
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user,
      Class: true,
      level: true,
      packageType: summaryPackageData?.name || null,
      tenureValue: summaryPackageData?.tenure_details.tenure_text.text || null
    });
    trackEvent({
      eventName: webCheckoutEventNames.editMobileOnVerifyOTP,
      payload
    });
    setFormPhase(loginPhases.generateOTP);
    reset();
  };

  const enableResendOTP = () => {
    setIsOTPTimerActive(false);
  };

  return {
    handleSubmit,
    onSubmit,
    editNumber,
    enableResendOTP,
    resendOTPAction,
    resendOTPCount,
    isValid,
    control,
    isOTPTimerActive,
    isVerificationLoading
  };
};
